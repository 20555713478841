/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import React from 'react';

import {

  Table,
  TableCell, TableContainer, TableRow, TableHead, TableBody, IconButton, Button, Grid, Select, Switch, FormControlLabel
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { BRANCH_SITUATION, BRANCH_SITUATION_MAPPER } from 'utils/constants';

const BranchesManagement = ({
  establishmentBranches,
  handleBranchChange,
  handleDeleteBranch = null,
  disabled,
  branches,
  handleIsMemberChange = null,
  handleIsActivityChange = null,
  establishmentIndex,
  currentSeasonId
}) => (
  <TableContainer style={{ overflow: 'hidden' }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Année réelle de naissance de l&apos;activité</TableCell>
          <TableCell align="center">Code et nom de la branche</TableCell>
          {handleIsActivityChange && (<TableCell align="center">Situation</TableCell>)}
          {handleIsActivityChange && (<TableCell>Date de modification de l&apos;activité</TableCell>)}
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {establishmentBranches && establishmentBranches.length > 0
          ? establishmentBranches.map((branch, index) => (
            (branch.status !== 'deleted')
          && (
            <TableRow
              key={branch.id}
            >
              <TableCell>
                <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                  <DatePicker
                    cancelLabel="Fermer"
                    disabled={disabled}
                    format="yyyy"
                    inputStyle={{ textAlign: 'center' }}
                    label="Année"
                    maxDate={new Date(`12/31/${currentSeasonId}`)}
                    minDate={new Date('01/01/2000')}
                    okLabel="Valider"
                    value={`01/01/${branch.seasonId}`}
                    views={['year']}
                    onChange={value => (establishmentIndex ? handleBranchChange(value, establishmentIndex, 'seasonId') : handleBranchChange(value, index, 'seasonId'))}
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
              <TableCell>
                <Select
                  color="secondary"
                  disabled={disabled}
                  fullWidth
                  labelId="id"
                  native
                  placeholder="Nature de l'établissement"
                  value={branch.branchId}
                  variant="outlined"
                  onChange={event => (establishmentIndex ? handleBranchChange(event.target.value, establishmentIndex, 'branchId') : handleBranchChange(event.target.value, index, 'branchId'))}
                >
                  {branches.map(b => <option key={b.id} value={b.id}>{`${b.code} - ${b.label}`}</option>)}
                </Select>
              </TableCell>
              {handleIsActivityChange && (
                <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                  <span style={{ color: BRANCH_SITUATION[branch.activityStatus ?? BRANCH_SITUATION_MAPPER.SUSPENDUE].color }}>
                    {BRANCH_SITUATION[branch.activityStatus ?? BRANCH_SITUATION_MAPPER.SUSPENDUE].label}
                  </span>
                </TableCell>
              )}
              <TableCell>
                <Grid container spacing={1} wrap="nowrap">
                  {handleDeleteBranch && (
                    <Grid item>
                      <IconButton
                        disabled={disabled}
                        onClick={() => handleDeleteBranch(index)}
                      >
                        <FontAwesomeIcon color="var(--delete-color)" icon={faTrashAlt} />
                      </IconButton>
                    </Grid>
                  )}
                  {handleIsMemberChange && (
                    <Grid item>
                      <FormControlLabel
                        control={<Switch checked={branch.isMember || false} onChange={event => handleIsMemberChange(event.target.checked, establishmentIndex, index, 'isMember')} />}
                        disabled={!branch.canBeModify}
                        label={branch.isMember ? 'Adhérent' : 'Non adhérent'}
                      />
                    </Grid>
                  )}
                  {handleIsActivityChange && (
                    <TableCell>
                      {branch.activityModificationDate === '' ? (<span>Aucune date</span>) : (
                        <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                          <DatePicker
                            cancelLabel="Fermer"
                            disabled={!branch.canBeModify}
                            format="dd/MM/yyyy"
                            inputStyle={{ textAlign: 'center' }}
                            okLabel="Valider"
                            value={branch.activityModificationDate === '' ? new Date() : branch.activityModificationDate}
                            onChange={value => handleBranchChange(value, establishmentIndex, index, 'activityModificationDate')}
                          />
                        </MuiPickersUtilsProvider>
                      )}

                    </TableCell>
                  )}
                </Grid>
              </TableCell>
              {handleIsActivityChange && (
                <TableCell>
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={!branch.canBeModify}
                      style={{ backgroundColor: 'white', fontSize: '12px' }}
                      variant="outlined"
                      onClick={() => handleBranchChange(
                        branch.activityStatus === BRANCH_SITUATION_MAPPER.EN_ACTIVITE
                          ? BRANCH_SITUATION_MAPPER.SUSPENDUE
                          : BRANCH_SITUATION_MAPPER.EN_ACTIVITE,
                        establishmentIndex,
                        index,
                        'activityStatus'
                      )}
                    >
                      {branch.activityStatus === BRANCH_SITUATION_MAPPER.EN_ACTIVITE ? 'Suspendre l\'activité' : 'Reprendre l\'activité' }
                    </Button>
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          )
          ))
          : (
            <TableRow
              id="emptyRow"
              key="emptyRow"
            >
              <TableCell align="right">Aucune branche à afficher</TableCell>
            </TableRow>
          )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default BranchesManagement;